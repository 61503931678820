.Stylesheet_trendingimg {
  transition: transform 0.1s;
  cursor: pointer;
}
.Stylesheet_trendingimg:hover {
  transform: scale(1.1);
}

.img:hover {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media screen and (max-width: 500px) {
  .slider3_div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .slider3_image {
    /* display: flex; */
    align-items: center;
    justify-self: center;
    flex-direction: column;
  }
  .style_course_heading {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.02em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .style_course_texts {
    font-family: Noto Serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
  }
  .style_course_button1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -50px;
  }

  .style_background {
    background-color: #fafafa;
    padding: none;
  }
  .c4_image {
    width: 90%;
  }
  .style_iandt {
    font-family: Noto Serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: center;
    padding-right: 20px;
  }
  .style_itext {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #666666;
    padding-right: 20px;
  }
  .abc {
    margin-top: 10px;
  }
  .map {
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
  }
  .map1 {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .form {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .style_course_button2 {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .style_course_button {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .list {
    width: 100;
    height: 200;
  }
  .apply1 {
    padding: 10px;
  }
  .apply2 {
    padding: 8px;
  }
  .header {
    margin-right: 50px;
  }
}
